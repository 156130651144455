import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../layout/theme-next';
import { NextTheme } from '../../../../layout/theme-next/provider';

export const styles = makeStyles((theme: NextTheme) => ({
  paper: ({ isPDF }: { isPDF: boolean } = { isPDF: false }) => ({
    height: isPDF ? '100%' : undefined,
    marginTop: 100,
    boxShadow: 'unset',
    backgroundColor: 'transparent',
  }),
  title: {
    position: 'fixed',
    top: 0,
    background: 'white',
    width: '100%',
    left: 0,
  },
  content: {
    overflow: 'hidden',
    height: '85%',
  },
  contentRoot: {
    textAlign: 'center',
  },
  buttonsContainer: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    marginRight: 30,
  },
  zoom: {
    maxWidth: '45vw',
    width: '100%',
    maxHeight: '90vh',
    margin: '0 auto',
  },
  backdrop: {
    zIndex: 9999,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    height: 17,
    width: 17,
  },
  [theme.media.mobile]: {
    '@global': {
      '.MuiDialogContent-root': {
        padding: '0 !important',
        marginTop: theme.spacing(1.5),
      },
    },
    dialogContent: {
      width: 340,
    },
    buttonsContainer: {
      marginRight: theme.spacing(0),
    },
    previousButton: {
      '&.MuiButton-root.Mui-disabled': {
        backgroundColor: `${Colors.statusClosed} !important`,
      },
    },
  },
}));
